import { render } from "solid-js/web";
import { Router, Route, Routes, A, useLocation } from "@solidjs/router";
import { createSignal, createEffect } from "solid-js";

import TestForm1 from "./pages/test_form_1/TestForm1.js";
import TestForm2 from "./pages/test_form_2/TestForm2.js";
import TestForm3 from "./pages/test_form_3/TestForm3.js";
import "./App.scss";

const NavBar = () => (
  <div className="NavBar">
    <A href="/">Home</A>
    <A href="/test_form_1">Test Form 1</A>
    <A href="/test_form_2">Test Form 2</A>
    <A href="/test_form_3">Test Form 3</A>
  </div>
);

const Content = () => {
  const location = useLocation();
  const [queryParams, setQueryParams] = createSignal({});

  // Function to parse query string into an object
  const parseQueryString = () => {
    const params = new URLSearchParams(location.search);
    const queryObject = {};
    for (const [key, value] of params.entries()) {
      queryObject[key] = value;
    }
    setQueryParams(queryObject);
  };
  createEffect(() => {
    parseQueryString();
  });

  return (
    <div className="Content">
      <Routes>
        <Route
          end
          path="/"
          component={() => (
            <span className="Content-text">{"Auto Detection Sandbox"}</span>
          )}
        />
        <Route end path="/test_form_1" component={() => <TestForm1 />} />
        <Route
          end
          path="/lead_gen_page_1"
          component={() => (
            <div>
              <span className="Content-text">{"lead gen"}</span>
              <br/>
              <span className="Content-text">{"THIS IS AN EMAIL: " + queryParams()['email']}</span>
            </div>
            
          )}
        />
        <Route end path="/test_form_2" component={() => <TestForm2 />} />
        <Route
          end
          path="/lead_gen_page_2"
          component={() => (
            <div>
              <span className="Content-text">{"Thank you for filling out the form!"}</span>
              <br/>
              <span className="Content-text">{"Please look out for email from not_user_pii@ignoreme.com"}</span>
            </div>
            
          )}
        />
        <Route end path="/test_form_3" component={() => <TestForm3 />} />
        <Route
          end
          path="/lead_gen_page_3"
          component={() => (
            <div>
              <span className="Content-text">{"Thank you for filling out the form!"}</span>
            </div>
            
          )}
        />
      </Routes>
    </div>
  );
};

const App = () => (
  <Router>
    <NavBar />
    <Content />
  </Router>
);

const rootElement = document.getElementById("root");
render(() => <App />, root);
